import React from 'react';
import { graphql } from 'gatsby';
import { Hat } from 'components/Hat/Hat';
import { Hello, Feed, Seo, SeoHidden, Contacts } from 'components';

export default ({
  data: {
    feed,
    site: {
      siteMetadata: { contacts }
    }
  }
}) => (
  <>
    <Contacts list={contacts} feed={feed} />
  </>
);

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        contacts
        offices
      }
    }
    feed: allMdx(filter: { fields: { id: { regex: "/^contacts//" } } }) {
      ...CardContact
    }
  }
`;
